import React, { useEffect, useState } from 'react'
import { Interweave } from "interweave";
import axios from "axios";
import Header from "../Components/Header";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/terms.css";

function AboutUs() {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
      var baseUrl = beckendLocalApiUrl;
    } else {
      baseUrl = beckendLiveApiUrl;
    }

    const [data, setData] = useState()
    const getdata = () => {

        // e.preventDefault();
        // const access_token = localStorage.getItem('token')
        // const headers = {
        //   Authorization: `Bearer ${access_token}`
        // }
        axios.get(baseUrl+`api/term/condition/About-Us`)
            .then((res) => {

                setData(res.data[0].Desc);
                // console.log(res.data[0].Type);
            })
    }

    useEffect(() => {
        getdata()
    }, [])

    return (
        <div>
          <div className="leftContainer">
            <Header />
            <div className="mt-5 py-4 px-3">
              <div className="m-3">
                <h1>About Us</h1>
                {/* <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Privacy Policy
                    </li>
                  </ol>
                </nav> */}
                {/* <h4>Introduction</h4> */}
                <>
                <p className="p2">
                    We took your love for LUDO and gave it an interesting spin!  vipPaysLudo is an Online gaming platform where you can have an amazing gaming experience with your loved ones, family or friends during leisure time. 
              </p>
              <p className="p3">
              What makes this game all the more special is - Video Call option during the gameplay which allows you to communicate with other players,Participating in contests to win exciting prizes. Multiplayer option that allows you to add your friends/family or anyone during the game, New Exciting themes that helps you enjoy the gaming experience in different visuals/audios and lastly, Team Up mode which helps you to form teams and play with each other. 
              </p>   
              <p className="p3">We took your love for LUDO and gave it an interesting spin!  vipPaysLudo is an Online gaming platform where you can have an amazing gaming experience with your loved ones, family or friends during leisure time.</p>
              <p className="p3">
              So what are you thinking of? Simply participate, compete and have some fun during your breaks!
              </p>
                </>
    
              </div>
            </div>
          </div>
          <div className="rightContainer">
            <Rightcontainer />
          </div>
        </div>
      );
}

export default AboutUs